@mixin telefono {
    @media (min-width: $telefono) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $tablet) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop) {
        @content;
    }
}

@mixin grid($columnas, $espaciado) {
    display: grid;
    grid-template-columns: repeat($columnas, 1fr);
    gap: $espaciado;
}

@mixin boton($bgColor, $display) {
    background-color: $bgColor;
    color: $blanco;
    font-weight: $bold;
    text-decoration: none;
    padding: 1.5rem 2rem;
    text-align: center;
    margin-top: $separacion / 2;
    display: $display;
    border-radius: 0.5rem;
    min-width: 15rem;

    &:hover {
        background-color: darken($bgColor, 8);
    }
}