@import 'base/normalize';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=PT+Sans:wght@400;700&display=swap');
@import 'base/variables';
@import 'base/mixins';
@import 'base/globales';
@import 'base/utilidades';
@import 'base/botones';
@import 'layout/footer';
@import 'layout/header';
@import 'layout/inicio';
@import 'layout/about';
@import 'layout/servicios';
@import 'layout/contacto';
