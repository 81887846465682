html {
    font-size: 55.5%;
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    font-family: $fuentePrincipal;
    font-size: 1.6rem;

    &.fijar-body {
        overflow: hidden;
    }
}

p{
    font-size: 2rem;
    color: $negro;
}

.contenedor {
    max-width:  120rem;
    width: 95%;
    margin: 0 auto;
}

a {
    text-decoration: none;
}
img{
    max-width: 100%;
}
h1,h2,h3 {
    margin: 0;
}
h1 {
    font-size: 4.8rem;
}
h2 {
    font-size: 4.2rem;
}
h3{
    font-size: 3.6rem;
}