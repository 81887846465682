.header {
  background-color: $grisSombra;
  padding: 1.5rem;
}

.barra {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @include tablet {
    gap: 2rem;
  }
}

.logo {
  width: 5rem;
}

.navegacion {
  grid-column: 1/3;
  grid-row: 2;

  @include tablet {
    grid-column: 2/3;
    grid-row: 1;

    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: flex-end;
  }

  .links {
    display: none;
    flex-direction: column;
    align-items: center;

    @include tablet {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    a {
      color: $blanco;
      font-size: 2.2rem;
      display: block;
      text-align: center;
      margin-top: 1.5rem;

      @include tablet {
        margin-right: 2rem;
        margin-top: 0;

        &:last-of-type {
          margin-right: 0;
        }
      }

      &:hover {
        color: $verde;
      }
    }
  }

  .links__show {
    display: flex;
  }

  .links__hide {
    display: none;
  }
}

.btn_menu {
  display: flex;
  justify-content: flex-end;
  color: $blanco;

  &:hover {
    color: $verde;
  }

  @include tablet {
    display: none;
  }
}
