
//colores
$verde: #4CB8B3;
$rosa: #F53756;
$amarillo: #FEF46D;
$morado: #752F97;
$negro: #000;
$blanco: #FFF;
$grisOscuro: #828282;
$grisSombra: #332F2C;

$celeste: #5DADE2;
$verdeBajo: #76D7C4;
$amarilloBajo: #F7DC6F;
$magenta: #FF33A5;

//inicio
$colorTitulo: black;
$colorTituloDesc: #332F2C;

//mediaquerys
$telefono: 480px;
$tablet: 768px;
$desktop: 1200px;

//fuentes
//$fuentePrincipal: 'Montserrat', sans-serif;
$fuentePrincipal: Sans-serif;
//$fuenteParrafos: 'PT Sans', sans-serif;
$delgada: 300;
$regular: 400;
$bold: 700;
$black: 900;

//Separaciones
$separacion: 5rem;